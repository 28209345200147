import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { IBedTypes } from '../../models';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getGeneralBedTypes = (state: State): any => state.generalBedTypes;

export const getBedTypes = (state: State): any => state.data;

export const selectBedTypesState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('bedTypes');

export const selectById = (acommodationsId: number) =>
  createSelector(
    selectBedTypesState,
    ({ data }) => data && data[acommodationsId],
  );

export const selectBedTypes: MemoizedSelector<
  object,
  IBedTypes
> = createSelector(selectBedTypesState, getBedTypes);

export const selectBedTypesError: MemoizedSelector<
  object,
  any
> = createSelector(selectBedTypesState, getError);

export const selectBedTypesIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectBedTypesState, getIsLoading);

export const selectBedTypesGeneralBedTypes: MemoizedSelector<
  object,
  IBedTypes
> = createSelector(selectBedTypesState, getGeneralBedTypes);
