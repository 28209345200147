import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetAddonCategoriesResponse } from '@app/models';
import { uniq } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponseSuccess } from '../core/models/response-sucess.model';

import {
  IBedsTypesCombination,
  IDeleteBedTypeCombination,
} from './../models/objects/bed-types.model';

@Injectable({
  providedIn: 'root',
})
export class BedTypesService {
  constructor(private http: HttpClient) {}

  loadBedTypes() {
    return this.http.get<GetAddonCategoriesResponse>(
      `general/accommodation/beds_types`,
    );
  }

  // load(accommodatioId: number[]) {
  //   return this.http.get<GetAddonCategoriesResponse>(
  //     `accommodation/${accommodatioId}/beds_types_combinations`,
  //   );
  // }

  loadBedTypesAccommodations(accommodationIds: number[]) {
    return forkJoin(
      uniq(accommodationIds).map(
        (accommodationId) =>
          this.http.get(
            `accommodation/${accommodationId}/beds_types_combinations`,
          ) as Observable<IResponseSuccess>,
      ),
    ).pipe(
      map((response) => {
        return uniq(accommodationIds).reduce((acc, curr, index) => {
          const data = response[index].data;
          if (data) {
            acc[curr] = data;
          }
          return acc;
        }, {});
      }),
    );
  }

  create(data: IBedsTypesCombination) {
    return this.http.post(
      `accommodation/${data.accommodation_id}/beds_types_combinations`,
      {
        ...data,
      },
    );
  }

  update(data: IBedsTypesCombination) {
    return this.http.put(
      `accommodation/${data.accommodation_id}/beds_types_combinations`,
      {
        ...data,
      },
    );
  }

  delete(data: IDeleteBedTypeCombination) {
    return this.http.delete(
      `accommodation/${data.accommodation_id}/beds_types_combination/${data.acc_bed_type_combination_id}`,
    );
  }
}
