import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      const {
        acc_bed_type_combination_id,
        accommodation_id,
      } = action.payload.request;
      const newData = state.data[accommodation_id].filter(
        ({ combination_id }) => combination_id !== acc_bed_type_combination_id,
      );
      return {
        ...state,
        data: { ...state.data, [accommodation_id]: newData },
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.RESET: {
      return {
        ...state,
        data: null,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_SELECT_SUCCESS: {
      return {
        ...state,
        generalBedTypes: action.payload.generalBedTypes,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
