import { IBedTypes } from '@app/models/objects/bed-types.model';

export interface State {
  isLoading?: boolean;
  data: { [accommodationId: number]: IBedTypes[] };
  generalBedTypes?: Array<{
    id: number;
    name: string;
    description: string;
  }>;
  error?: any;
}

export const initialState: State = {
  isLoading: false,
  data: null,
  generalBedTypes: null,
  error: null,
};
