import { Action } from '@ngrx/store';

import { IBedTypes } from '../../models';

import {
  IBedsTypesCombination,
  IDeleteBedTypeCombination,
  IGeneralBedType,
} from './../../models/objects/bed-types.model';

export enum ActionTypes {
  LOAD_REQUEST = '[Bed Types] Load Request',
  LOAD_FAILURE = '[Bed Types] Load Failure',
  LOAD_SUCCESS = '[Bed Types] Load Success',

  LOAD_SELECT_REQUEST = '[Bed Types] Load Select Request',
  LOAD_SELECT_FAILURE = '[Bed Types] Load Select Failure',
  LOAD_SELECT_SUCCESS = '[Bed Types] Load Select Success',

  UPDATE_REQUEST = '[Bed Types] Update Request',
  UPDATE_FAILURE = '[Bed Types] Update Failure',
  UPDATE_SUCCESS = '[Bed Types] Update Success',

  CREATE_REQUEST = '[Bed Types] Create Request',
  CREATE_FAILURE = '[Bed Types] Create Failure',
  CREATE_SUCCESS = '[Bed Types] Create Success',

  DELETE_REQUEST = '[Bed Types] Delete Request',
  DELETE_FAILURE = '[Bed Types] Delete Failure',
  DELETE_SUCCESS = '[Bed Types] Delete Success',

  RESET = '[Bed Types] Reset',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { accommodationId: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { [accommodationId: number]: IBedTypes[] }) {}
}

export class LoadSelectRequestAction implements Action {
  readonly type = ActionTypes.LOAD_SELECT_REQUEST;
}

export class LoadSelectFailureAction implements Action {
  readonly type = ActionTypes.LOAD_SELECT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSelectSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SELECT_SUCCESS;
  constructor(public payload: { generalBedTypes: IGeneralBedType[] }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: { request: IBedsTypesCombination; propertyId?: number },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload?: { propertyId?: number }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: { request: IBedsTypesCombination; propertyId?: number },
  ) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload?: { propertyId?: number }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: { request: IDeleteBedTypeCombination; propertyId?: number },
  ) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(
    public payload: { request: IDeleteBedTypeCombination; propertyId?: number },
  ) {}
}

export class ResetAction implements Action {
  readonly type = ActionTypes.RESET;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadSelectRequestAction
  | LoadSelectFailureAction
  | LoadSelectSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | CreateRequestAction
  | CreateSuccessAction
  | CreateFailureAction
  | DeleteRequestAction
  | DeleteSuccessAction
  | DeleteFailureAction
  | ResetAction;
