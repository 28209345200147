import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { BedTypesService } from '@app/services/bed-types.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';

import * as featureActions from './actions';

@Injectable()
export class BedTypesStoreEffects {
  constructor(
    private dataService: BedTypesService,
    private actions$: Actions,
    private _notifications: NotificationService,
    private _translate: TranslateService,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService
          .loadBedTypesAccommodations(action.payload.accommodationId)
          .pipe(
            map((data) => {
              return new featureActions.LoadSuccessAction(data);
            }),
            catchError((error) =>
              of(new featureActions.LoadFailureAction({ error })),
            ),
          ),
      ),
    ),
  );

  loadSelectRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadSelectRequestAction>(
        featureActions.ActionTypes.LOAD_SELECT_REQUEST,
      ),
      switchMap((action: featureActions.LoadSelectRequestAction) =>
        this.dataService.loadBedTypes().pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSelectSuccessAction({
                generalBedTypes: data,
              }),
          ),
          catchError((error) =>
            of(new featureActions.LoadFailureAction({ error })),
          ),
        ),
      ),
    ),
  );

  updateRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(
        ({
          payload: { request, propertyId },
        }: featureActions.UpdateRequestAction) =>
          this.dataService.update(request).pipe(
            map((response: IResponseSuccess) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.update_success',
                  {
                    param: this._translate.instant('beds_types'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.UpdateSuccessAction({ propertyId });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  createRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap(
        ({
          payload: { request, propertyId },
        }: featureActions.CreateRequestAction) =>
          this.dataService.create(request).pipe(
            mergeMap((response: IResponseSuccess) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.create_success',
                  {
                    param: this._translate.instant('beds_types'),
                  },
                ),
                type: 'success',
              });
              return [
                new featureActions.CreateSuccessAction({ propertyId }),
                new featureActions.LoadRequestAction({
                  accommodationId: [request.accommodation_id],
                }),
              ];
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  deleteRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { request, propertyId },
        }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(request).pipe(
            map((response: IResponseSuccess) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.delete_success',
                  {
                    param: this._translate.instant('beds_types'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.DeleteSuccessAction({
                request,
                propertyId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );
}
